<template>
  <a-layout class="view">
    <!-- 页面 -->
    <router-view />
  </a-layout>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
    }
  },
  created () {
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
// .page {
//   width: 100%;
//   height: 100%;
//   border-radius: 4px;
// }
.view {
  width: 100%;
  margin-top: 24px;
  // background-color: pink;
}
</style>
